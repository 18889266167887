<template>
  <base-section id="map" space="0">
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14025.004517322872!2d77.4125177!3d28.5020912!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce95026f60a85%3A0x8f00c12dca7f2ee4!2sGM%20IT%20PARK!5e0!3m2!1sen!2sus!4v1683236763038!5m2!1sen!2sus"
      style="border: 0; display: block"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
export default {
  name: "SectionMap",

  provide: {
    heading: { align: "center" },
  },
};
</script>
